import {
    faBell,
    faBellSlash,
    faComment,
    faExclamationCircle,
    faExternalLinkAlt,
    faEyeSlash,
    faGlobeAmericas,
    faLock
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {
    Dispatch,
    FunctionComponent,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    SetStateAction,
    useState
} from 'react';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import {LoanStatus} from '../../../common/models/LoanStatus';
import {AdvertisementSite} from '../models/AdvertisementSite';
import NumberInputField, {NumberInputFieldKind} from '../../../common/components/input-fields/NumberInputField';
import TextInputField from '../../../common/components/input-fields/TextInputField';
import DateTimeInputField, {DateTimeInputFieldKind} from '../../../common/components/input-fields/DateTimeInputField';
import {connect} from 'react-redux';
import {SettingsProps} from '../../../common/interfaces/SettingsProps';
import {getMyApplicationUrl} from '../../../common/helpers/settingsHelpers';
import {loanStatusToColor} from '../../../common/helpers/loanStatusToColor';
import {ApplicationSource} from '../models/ApplicationSource';
import Tooltip from '../../../common/components/Tooltip';
import {ProductType} from '../../../common/models/ProductType';
import {ManageableComponentProps} from '../../../common/interfaces/ManageableComponentProps';
import EditIcons from './EditIcons';
import {putFollowUpReminder} from '../api/putFollowUpReminder';
import {ApplicationRouteParams} from '../../../common/interfaces/ApplicationRouteParams';
import {bindActionCreators} from 'redux';
import {showToastMessage, ShowToastMessageProps} from '../../../common/actions/ToastMessagesActionCreator';
import getProgressCompletionStyling from '../helpers/getProgressCompletionStyling';
import {UserProps} from '../../../common/interfaces/UserProps';
import OriginIcon from '../../../common/components/OriginIcon';
import ReadonlyCustomInputField from "../../../common/components/input-fields/ReadonlyCustomInputField";
import {ConsumerLoan} from "../models/ConsumerLoan";
import {validateApplication} from "./ApplicationValidator";
import {getTranslationKey} from "../../../common/helpers/getTranslationKey";
import {isLimitedCaseWorker} from "../../../common/helpers/limitedCaseWorkerLogic";
import {NavigateFunction, useNavigate, useParams} from "react-router";
import {Params} from "react-router-dom";
import {PublishersProps} from '../../../common/interfaces/PublishersProps';
import {PublishersActionsCreator, PublishersActionsCreatorProps} from '../actions/PublishersActionsCreator';
import CopyToClipboardButton, {CopyToClipboardButtonProps} from "../../../common/components/CopyToClipboardButton";
import {isAdmin} from "../../../common/helpers/isAdmin";
import {RedemptionSource} from "../models/RedemptionSource";
import {Redemption} from "../models/Redemption";

export interface ApplicationManageableData {
    followUpDate?: Date | null;
    paymentDate?: Date | string;
    commission?: number | null;
}

interface ApplicationOwnProps extends ManageableComponentProps<ApplicationManageableData> {
    application: ConsumerLoan;
    advertisementSites: AdvertisementSite[];
    applicationSources: ApplicationSource[];
    countryId: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onRefresh: () => void;
}

type ApplicationProps = ApplicationOwnProps & SettingsProps & UserProps & ShowToastMessageProps & LocalizeContextProps & PublishersProps & PublishersActionsCreatorProps;

interface ApplicationState extends ApplicationManageableData {
    editableProperties: Array<keyof ApplicationManageableData>;
}

const Application: FunctionComponent<ApplicationProps> = (props) => {
    const [state, setState] = useState<ApplicationState>({ editableProperties: [], followUpDate: undefined, paymentDate: undefined, commission: undefined });
    const params = useParams<ApplicationRouteParams>();
    const navigate = useNavigate();
    // const onClick = state.editableProperty ? undefined : props.onClick;
    const onClick = state.editableProperties.length > 0 ? undefined : props.onClick;

    return (
        <div className={`left-column column-card ${loanStatusToColor(props.application.loanStatus, 'status-', props.application.details.applicationFormProgress)}`} onClick={onClick}>
            <div className="card-heading">
                <span className="title">
                    <Translate id="APPLICATION" />
                </span>
                <span className="options">
                    {renderIcons(props, state, setState)}
                </span>
            </div>
            <div className="card-content row">
                {renderCardContents(props, state, setState, params, navigate)}
            </div>
        </div>
    );
};

function renderCardContents(
    props: ApplicationProps,
    state: ApplicationState,
    setState: Dispatch<SetStateAction<ApplicationState>>, 
    params: Partial<Params<keyof ApplicationRouteParams>>, 
    navigate: NavigateFunction
): ReactNode[] {
    const cardItems: ReactNode[] = [];

    const source = props.applicationSources.filter((x) => x.id === props.application.details.sourceId)[0];

    const followUpDate = state.editableProperties.includes('followUpDate') ? state.followUpDate : props.application.details.followUpDate;
    const paymentDate = state.editableProperties.includes('paymentDate') ? state.paymentDate : props.application.details.paymentDate;
    const commission = state.editableProperties.includes('commission') ? state.commission : props.application.details.commission;

    const personId = params.applicantId || '';
    const applicationId = params.applicationId || '';

    const onFollowUpClick: MouseEventHandler<HTMLDivElement> | undefined =
        props.canEnterEdit ?
            (e) => {
                props.enteredEditCallBack();
                setState({ 
                    editableProperties: ['followUpDate'], 
                    followUpDate: props.application.details.followUpDate 
                });
                e.stopPropagation();
            } : undefined;

    const onFollowUpChange = (followUpDate: Date | null) => setState((s) => ({ ...s, followUpDate }));

    const onPaymentDataClick: MouseEventHandler<HTMLDivElement> | undefined =
        props.canEnterEdit && isAdmin(props) ?
            (e) => {
                props.enteredEditCallBack();
                setState({ 
                    editableProperties: ['paymentDate', 'commission'], 
                    paymentDate: props.application.details.paymentDate,
                    commission: props.application.details.commission
                });
                e.stopPropagation();
            } : undefined;

    const onPaymentDateChange = (paymentDate: Date) => setState((s) => ({ ...s, paymentDate }));
    const onCommissionChange = (commission: number | null) => setState((s) => ({...s, commission }));

    const translate = (key: string) => props.translate(key).toString();

    const validationMessages = validateApplication(props.application).map(result => translate(`VALIDATION_RESULTS.${result}`));

    const onReminderClick: MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        const followUpReminder = props.application.details.followUpReminder;
        putFollowUpReminder(personId, applicationId, !followUpReminder).then(() => {
            props.onRefresh();
            props.showToastMessage('success', translate('UPDATE_FOLLOW_UP_REMINDER'), translate(followUpReminder ? 'DISABLED_FOLLOW_UP_REMINDER' : 'ENABLED_FOLLOW_UP_REMINDER'));
        });
    };
    
    const renderValidationTooltip = () => {
        if (validationMessages.length === 0) {
            return null;
        }
        return (
            <Tooltip 
                content={
                    <div style={{textAlign: 'left'}}>
                        <Translate id="VALIDATION_SUMMARY.LOAN_AMOUNT_INVALID"/>
                        <ul style={{paddingInlineStart: '24px', marginBottom: '0'}}>
                            {validationMessages.map(m => <li key={m}>{m}</li>)}
                        </ul>
                    </div>
                }
            >
                <FontAwesomeIcon style={{color: '#F05B59'}} icon={faExclamationCircle}/>
            </Tooltip>
        )
    }

    cardItems.push(
        <div className="card-content-item col-6" key={'card-content-item-1'}>
            <NumberInputField
                name="loanAmount"
                value={props.application.details.loanAmount}
                afterContent={renderValidationTooltip()}
                descriptionKey="REQUESTED_LOAN_AMOUNT"
                kind={NumberInputFieldKind.Money}
                countryId={props.countryId}
            />
        </div>
    );

    if (props.application.details.productType !== ProductType.CreditCard) {
        cardItems.push(
            <div className="card-content-item col-6" key={'card-content-item-3'}>
                <NumberInputField
                    name="repaymentPeriod"
                    value={props.application.details.repaymentPeriod / 12}
                    descriptionKey="REPAYMENT_PERIOD"
                    kind={NumberInputFieldKind.Years}
                    max={15}
                />
            </div>
        );
    }

    if (source) {
        cardItems.push(
            <div className="card-content-item col-6" key={'card-content-item-10'}>
                <div className="value"><span>{source.name}</span></div>
                <div className="description"><Translate id="SOURCE" /></div>
            </div>
        );
    }

    if (props.application.details.advertisementId && props.advertisementSites.length > 0) {
        const advertisementId = props.application.details.advertisementId;
        const advertisementSite = props.advertisementSites.filter((x) => x.advertisements.map((z) => (z.id)).indexOf(advertisementId) > -1)[0];
        const advertisement = advertisementSite.advertisements.filter((x) => x.id === advertisementId)[0];
        
        if (!isLimitedCaseWorker(props)) {
            cardItems.push(
                <div className="card-content-item col-6" key={'card-content-item-2'}>
                    <div className="value"><span>{advertisementSite.name}</span></div>
                    <div className="description"><Translate id="ADVERTISEMENT_SITE" /></div>
                </div>
            );
        }

        cardItems.push(
            <div className="card-content-item col-6" key={'card-content-item-7'}>
                <div className="value"><span>{advertisement.name}</span></div>
                <div className="description"><Translate id="ADVERTISEMENT" /></div>
            </div>
        );
    }
    
    cardItems.push(
        <div className="card-content-item col-6" key={'card-content-item-11'}>
            <div className="value"><span>{renderPublisherId(props.application.details.publisherId, props)}</span></div>
            <div className="description"><Translate id="PUBLISHER_ID" /></div>
        </div>
    );

    cardItems.push(
        <div className="card-content-item col-6" key={'card-content-item-4'}>
            <DateTimeInputField
                name="submittedDate"
                value={props.application.details.submittedDate}
                descriptionKey="SUBMITTED_DATE"
            />
        </div>
    );

    cardItems.push(
        <div className="card-content-item col-6" key={'card-content-item-5'}>
            <TextInputField<CopyToClipboardButtonProps>
                name="bankAccount"
                value={props.application.details.bankAccount}
                descriptionKey="BANK_ACCOUNT"
                wrapper={CopyToClipboardButton}
                wrapperProps={{content: props.application.details.bankAccount}}
            />
        </div>
    );

    const renderReminderIcon = () => {
        const divStyle = { color: '#707070', fontSize: '12px', marginLeft: '3px' };
        const tooltipContent = <Translate id={props.application.details.followUpReminder ? 'REMINDERS_ENABLED' : 'REMINDERS_DISABLED'} />;

        return state.editableProperties.length === 0 && followUpDate !== undefined && followUpDate !== null ? (
            <div style={divStyle} onClick={onReminderClick}>
                <Tooltip content={tooltipContent}>
                    <FontAwesomeIcon icon={props.application.details.followUpReminder ? faBell : faBellSlash} />
                </Tooltip>
            </div>
        ) : null;
    };

    cardItems.push(
        <div style={{ display: 'flex' }} className="card-content-item col-6" key={'card-content-item-6'} >
            <div onClick={onFollowUpClick}>
                <DateTimeInputField
                    name="followUpDate"
                    value={followUpDate}
                    descriptionKey="FOLLOW_UP_DATE"
                    editMode={state.editableProperties.includes('followUpDate')}
                    defaultOpen={true}
                    onValueChanged={onFollowUpChange}
                    kind={DateTimeInputFieldKind.DateWithTime}
                    timeFrom={8}
                    timeTo={20}
                />
            </div>
            {renderReminderIcon()}
        </div>
    );

    if (props.application.details.paymentDate) {
        cardItems.push(
            <div onClick={onPaymentDataClick} className="card-content-item col-6" key={'card-content-item-15'}>
                <DateTimeInputField
                    name="paymentDate"
                    value={paymentDate}
                    descriptionKey="APPLICANTS_VIEW.TABLE_HEADERS.PAYMENT_DATE"
                    editMode={state.editableProperties.includes('paymentDate')}
                    onValueChanged={paymentDate => paymentDate && onPaymentDateChange(paymentDate)}
                    kind={DateTimeInputFieldKind.Date}
                />
            </div>
        );
    }

    if (props.application.details.commission) {
        cardItems.push(
            <div onClick={onPaymentDataClick} className="card-content-item col-6" key={'card-content-item-8'}>
                <NumberInputField
                    name="commission"
                    value={commission}
                    descriptionKey="COMMISSION"
                    editMode={state.editableProperties.includes('commission')}
                    onValueChanged={commission => commission && onCommissionChange(commission)}
                    kind={NumberInputFieldKind.Money}
                    countryId={props.countryId}
                />
            </div>
        );
    }

    if (props.application.details.firstConversionCost) {
        cardItems.push(
            <div className="card-content-item col-6" key={'card-content-item-16'}>
                <NumberInputField
                    name="firstConversionCost"
                    value={props.application.details.firstConversionCost}
                    descriptionKey="FIRST_CONVERSION_COST"
                    kind={NumberInputFieldKind.Money}
                    countryId={props.countryId}
                />
            </div>
        );
    }

    if (props.application.details.secondConversionCost) {
        cardItems.push(
            <div className="card-content-item col-6" key={'card-content-item-17'}>
                <NumberInputField
                    name="secondConversionCost"
                    value={props.application.details.secondConversionCost}
                    descriptionKey="SECOND_CONVERSION_COST"
                    kind={NumberInputFieldKind.Money}
                    countryId={props.countryId}
                />
            </div>
        );
    }

    if (props.application.details.applicationFormProgress !== null && props.application.loanStatus === LoanStatus.Incomplete) {
        cardItems.push(
            <div className="card-content-item col-6" key={`card-content-item-12`}>
                <div className="value">
                    <span style={getProgressCompletionStyling(props.application.details.applicationFormProgress)}>
                        {Math.min(props.application.details.applicationFormProgress, 100)}%
                    </span>
                </div>
                <div className="description">
                    <Translate id="FORM_COMPLETION_PROGRESS" />
                </div>
            </div>
        );
    }

    if (props.application.details.parentId && props.application.details.parentPersonId) {
        const {parentId, parentPersonId} = {...props.application.details};
        const onClick = (event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
            navigate(`/applicant/${parentPersonId}/application/${parentId}`);
        }
        cardItems.push(
            <div className="card-content-item col-6" key={'card-content-item-13'}>
                <ReadonlyCustomInputField descriptionKey="PARENT_APPLICATION">
                    <span className="parent-application" onClick={onClick}>
                        {parentId}
                        <FontAwesomeIcon style={{marginLeft: '4px'}} icon={faExternalLinkAlt}/>
                    </span>
                </ReadonlyCustomInputField>
            </div>
        );
    }
    
    if (props.application.details.purpose) {
        cardItems.push(
            <div className="card-content-item col-6" key={'card-content-item-14'}>
                <div className="value">
                    <span>
                        <Translate id={getTranslationKey(props.application.details.purpose, 'PURPOSE')} />
                    </span>
                </div>
                <div className="description">
                    <Translate id="PURPOSE.LABEL" />
                </div>
            </div>
        );
    }

    return cardItems;
}

function renderIcons(props: ApplicationProps & SettingsProps, state: ApplicationState, setState: Dispatch<SetStateAction<ApplicationState>>) {
    const notificationsIcon = props.application.details.disableNotifications ? faBellSlash : faBell;
    const myApplicationHref = getMyApplicationUrl(props, props.application.details.sourceId, props.application.details.publicId);
    const exitedEditCallback = (cancelled: boolean) => {
        setState({
            editableProperties: [],
            followUpDate: undefined,
            paymentDate: undefined
        });
        props.exitedEditCallBack(cancelled);
    };

    if (state.editableProperties.length > 0) {
        const data: ApplicationManageableData = {
          followUpDate: state.editableProperties.includes('followUpDate') ? state.followUpDate || null : undefined,
          paymentDate: state.editableProperties.includes('paymentDate')  ? state.paymentDate || props.application.details.paymentDate : undefined,
          commission: state.editableProperties.includes('commission')  ? state.commission || props.application.details.commission : undefined
        }; 

        return (
            <EditIcons
                {...props}
                exitedEditCallBack={exitedEditCallback}
                editMode={true}
                data={data}
            />
        );
    }

    return (
        <React.Fragment>
            {renderDebtsSource(props.application.redemptions)}
            {renderHidden(props.application.details.hidden)}
            {renderLock(props.canEnterEdit)}
            {renderComment(props.application.details.customerComment)}
            <span>
                <FontAwesomeIcon icon={notificationsIcon} />
            </span>
            <span>
                <a href={myApplicationHref} target="_blank" rel="noreferrer" onClick={stopPropagation}>
                    <FontAwesomeIcon icon={faGlobeAmericas} />
                </a>
            </span>
            <span>
                <OriginIcon origin={props.application.details.origin}/>
            </span>
        </React.Fragment>
    );

}

function renderPublisherId(publisherId: string | undefined, props: PublishersProps & PublishersActionsCreatorProps) {
    let id = (publisherId || '').split('/')[0];

    if (id !== undefined && id !== null && id !== '') {
        if (props.publishersData === undefined || props.publishersData.publishers === undefined) {
            props.loadPublishers();
            return id;
        }

        const publisher = props.publishersData.publishers.find(p => p.externalId === id);
        if (publisher) {
            id = `${publisher.externalId} (${publisher.name})`
        }
    }
    return id || '-';
}

function renderComment(comment: string) {
    if (!comment) {
        return null;
    }

    return <span><Tooltip content={comment} placement="auto"><FontAwesomeIcon icon={faComment} /></Tooltip></span>;
}

function renderLock(canEnterEdit: boolean) {
    if (canEnterEdit === true) {
        return null;
    }

    return <span><Tooltip content={<Translate id="APPLICATION_READONLY" />}><FontAwesomeIcon icon={faLock} /></Tooltip></span>;
}

function renderHidden(hidden: boolean) {
    if (hidden !== true) {
        return null;
    }

    return <span><Tooltip content={<Translate id="HIDDEN_APPLICATION"/>}><FontAwesomeIcon icon={faEyeSlash} /></Tooltip></span>;
}

function renderDebtsSource(redemptions?: Redemption[]) {
    if (!redemptions?.some(r => r.source === RedemptionSource.NorskGjeldsinformasjon)) {
        return null;
    }
    return (
        <span>
            <Tooltip content={<Translate id="REDEMPTION_SOURCE.NORSK_GJELDSINFORMASJON" />}>
                <img
                    alt="Norsk Gjeldsinformasjon"
                    height="16"
                    width="16"
                    style={{marginTop: '-4px'}}
                    src="https://cdn.motty.no/icons/norsk_gjeldsinformasjon.png" />
            </Tooltip>
        </span>
    );
}

const stopPropagation: MouseEventHandler<HTMLAnchorElement> = (e) => e.stopPropagation();

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.settingsActionsReducer,
    ...state.publishersReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({ showToastMessage, ...PublishersActionsCreator } as any, dispatch);

export default connect<SettingsProps & UserProps & PublishersProps, ShowToastMessageProps & PublishersActionsCreatorProps, ApplicationOwnProps, any>
    (mapStateToProps, mapActionCreatorsToProps)(withLocalize(Application));
