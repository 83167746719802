import React, { FunctionComponent, useState, Dispatch, SetStateAction, ReactNode } from 'react';
import { Redemption } from '../models/Redemption';
import InputField from '../../../common/components/input-fields/InputField';
import Autosuggest, { SuggestionsFetchRequestedParams, RenderSuggestionsContainerParams } from 'react-autosuggest';
import { Bank } from '../models/Bank';
import { connect } from 'react-redux';
import { MetadataProps } from '../../../common/interfaces/MetadataProps';
import NumberInputField, { NumberInputFieldKind } from '../../../common/components/input-fields/NumberInputField';
import { BooleanInputField } from '../../../common/components/input-fields/BooleanInputField';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import { Translate } from 'react-localize-redux';
import { useValidation } from '../../../common/helpers/useValidation';
import { DebtType } from '../models/DebtType';

interface EditLoanFormProps {
    redemption: Redemption;
    countryId: number;
    errors: string[];
    isComplete: boolean;
    onRedemptionChanged: (oldRedemption: Redemption, newRedemption: Redemption) => void;
}

type SetSuggestionsAction = Dispatch<SetStateAction<Bank[]>>;

const debtOwnerOptions = [
    { id: 'applicant', name: <Translate id="DEBT_OWNER.APPLICANT" /> },
    { id: 'co applicant', name: <Translate id="DEBT_OWNER.CO_APPLICANT" /> },
    { id: 'shared with co applicant', name: <Translate id="DEBT_OWNER.SHARED_WITH_CO_APPLICANT" /> }
];

const EditLoanForm: FunctionComponent<EditLoanFormProps & MetadataProps> = (props) => {
    const [bankSuggestions, setBankSuggestions] = useState<Bank[]>([]);
    const onSuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams) => handleSuggestionsFetchRequested(request, props.metadata.banks, setBankSuggestions);
    const onSuggestionsClearRequested = () => setBankSuggestions([]);
    const onValueChanged: (value: string | number | Date | boolean | null | undefined, name: string) => void = (value, name) =>
        props.onRedemptionChanged(props.redemption, { ...props.redemption, [name]: value });
    const [errorCode, internalError, ref, onTouched] = useValidation({
        value: props.redemption.lender,
        required: props.isComplete ? 'RedemptionLenderRequired' : undefined,
        errors: props.errors
    });
    const inputProps = {
        value: props.redemption.lender || '',
        onChange: (_, { newValue }) => {
            onTouched();
            props.onRedemptionChanged(props.redemption, { ...props.redemption, lender: newValue });
        },
        className: 'form-control'
    };
    const renderInputComponent = (p) => <input {...p} ref={ref} />;

    const renderCreditLimit = () =>
        props.redemption.debtType === DebtType.CreditCard
            ? 
            <NumberInputField
                name="creditLimit"
                value={props.redemption.creditLimit}
                kind={NumberInputFieldKind.Money}
                countryId={props.countryId}
                editMode={true}
                descriptionKey="CREDIT_LIMIT"
                onValueChanged={onValueChanged}
                nullable={true}
            />
        : null;

    return (
        <div>
            <InputField descriptionKey="BANK" editMode={true} error={internalError} errorCode={errorCode}>
                <Autosuggest
                    suggestions={bankSuggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    renderInputComponent={renderInputComponent}
                    inputProps={inputProps}
                />
            </InputField>
            <NumberInputField
                name="balance"
                value={props.redemption.balance}
                kind={NumberInputFieldKind.Money}
                countryId={props.countryId}
                editMode={true}
                descriptionKey="LOAN_AMOUNT"
                onValueChanged={onValueChanged}
                required={props.isComplete && props.redemption.debtType !== DebtType.CreditCard && props.redemption.debtType !== DebtType.ConsumerLoan ? 'RedemptionBalanceRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
            />
            {renderCreditLimit()}
            <div style={{ display: 'flex' }}>
                <div>
                    <NumberInputField
                        name="repaymentMonthlyCost"
                        value={props.redemption.repaymentMonthlyCost}
                        kind={NumberInputFieldKind.MoneyPerMonth}
                        countryId={props.countryId}
                        editMode={true}
                        descriptionKey="INSTALLMENT_PER_MONTH"
                        onValueChanged={onValueChanged}
                        nullable={true}
                    />
                </div>
                <div style={{ marginLeft: '19px', minWidth: '97px', maxWidth: '97px' }}>
                    <NumberInputField
                        name="interestRate"
                        value={props.redemption.interestRate}
                        kind={NumberInputFieldKind.Percent}
                        editMode={true}
                        descriptionKey="INTEREST_RATE"
                        onValueChanged={onValueChanged}
                        nullable={true}
                        step={0.01}
                    />
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div>
                    <NumberInputField
                        name="remainingRepaymentPeriod"
                        value={props.redemption.remainingRepaymentPeriod}
                        kind={NumberInputFieldKind.Months}
                        editMode={true}
                        descriptionKey="REMAINING_REPAYMENT_PERIOD"
                        onValueChanged={onValueChanged}
                    />
                </div>
                <div style={{ marginLeft: '19px' }}>
                    <BooleanInputField
                        name="refinance"
                        value={props.redemption.refinance}
                        editMode={true}
                        disabled={false}
                        descriptionKey="REFINANCE"
                        onValueChanged={onValueChanged}
                    />
                </div>
            </div>
            <DropdownInputField
                name="debtOwner"
                value={props.redemption.debtOwner}
                editMode={true}
                descriptionKey="RESPONSIBLE_FOR_LOAN"
                items={debtOwnerOptions}
                onValueChanged={onValueChanged}
                required={props.isComplete ? 'RedemptionDebtOwnerRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
            />
        </div>
    );
};

function getSuggestionValue(bank: Bank) {
    return bank.name;
}

function renderSuggestion(bank: Bank) {
    return <div>{bank.name}</div>;
}

function renderSuggestionsContainer(params: RenderSuggestionsContainerParams): ReactNode {
    return (
        <div {...params.containerProps}>
            <div className="suggestion-wrapper">{params.children}</div>
        </div>
    );
}

function handleSuggestionsFetchRequested(request: SuggestionsFetchRequestedParams, banks: Bank[], setBankSuggestions: SetSuggestionsAction) {
    if (request.reason === 'input-changed') {
        const filter = request.value.toLocaleLowerCase();
        setBankSuggestions(filterSuggestions(banks, filter));
    }
}

function filterSuggestions(banks: Bank[], filter: string) {
    return banks
        .filter((b) => b.name.toLocaleLowerCase().includes(filter));
}

const mapStateToProps = (state: any) => ({
    ...state.metadataActionsReducer
});

export default connect<MetadataProps, {}, EditLoanFormProps, any>(mapStateToProps)(EditLoanForm);
