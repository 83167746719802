import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const updatePaymentData = async (personId: string | number, loanId: string | number, paymentDate: Date | string, commission: number | null): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`api/applicants/${personId}/consumerloans/${loanId}/updatePaymentData`, { paymentDate, commission });

    return new CommandResult(result);
};
