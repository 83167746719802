import React, {FunctionComponent, useEffect, useState} from "react";
import "./MarketingListView.css";
import {getCountryId, getMainBackgroundColorStyle} from "../../../common/helpers/settingsHelpers";
import {connect} from "react-redux";
import {SettingsProps} from "../../../common/interfaces/SettingsProps";
import {useLocation, useNavigate, useParams} from "react-router";
import {UserProps} from "../../../common/interfaces/UserProps";
import {MarketingListCustomersFilter} from "../../model/MarketingListCustomersFilter";
import CustomersFilterContainer from "./CustomersFilter/CustomersFilterContainer";
import CustomersTable from "./CustomersTable/CustomersTable";
import {MarketingList} from "../../model/MarketingList";
import {MarketingListCustomer} from "../../model/MarketingListCustomer";
import {bindActionCreators, Dispatch} from "redux";
import {SetTitleActionCreator} from "../../../common/actions/SetTitleActionCreator";
import {SetApplicationTitleActionProps} from "../../../common/interfaces/SetApplicationTitleActionProps";
import {MarketingListCustomerStatus} from "../../model/MarketingListCustomerStatus";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {createMarketingList as createMarketingListAPI} from "../../api/createMarketingList";
import CreateMarketingListModal from "../menus/CreateMarketingListModal";
import MarketingListMenu from "../menus/MarketingListMenu";
import {handleCommandResult} from "../../../common/helpers/CommandResult";
import {deleteMarketingList as deleteMarketingListAPI} from "../../api/deleteMarketingList";
import {showToastMessage, ShowToastMessageProps} from "../../../common/actions/ToastMessagesActionCreator";
import {isValueSet} from "../../../common/helpers/isValueSet";
import {putFollowUpDate} from "../../../applications/applicant/api/putFollowUpDate";
import {postMarketingListCustomerStatus} from "../../api/postMarketingListCustomerStatus";
import TextInputField from "../../../common/components/input-fields/TextInputField";
import {countMarketingListCustomers} from "../../api/countMarketingListCustomers";
import {getMarketingListCustomers as getMarketingListCustomersAPI} from "../../api/getMarketingListCustomers";
import {getMarketingList as getMarketingListAPI} from "../../api/getMarketingList";
import {putMarketingList} from "../../api/putMarketingList";
import {useNavigationConfirmation} from "../../../common/helpers/useNavigationConfirmation";
import {Button} from "react-bootstrap";
import {getMarketingListCustomersPreview} from "../../api/getMarketingListCustomersPreview";
import {countMarketingListCustomersPreview} from "../../api/countMarketingListCustomerPreview";
import {postComment} from "../../../applications/applicant/api/postComment";
import {isAdmin} from "../../../common/helpers/isAdmin";

type MarketingListViewRouteParams = 'listId';

type MarketingListViewProps = SettingsProps & UserProps & SetApplicationTitleActionProps & LocalizeContextProps & ShowToastMessageProps;

const MarketingListView: FunctionComponent<MarketingListViewProps> = (props) => {
    const params = useParams<MarketingListViewRouteParams>();
    const navigate = useNavigate();
    const location = useLocation();
    const [marketingList, setMarketingList] = useState<MarketingList>();
    const [customers, setCustomers] = useState<MarketingListCustomer[]>([]);
    const [customersCount, setCustomersCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOutdated, setIsOutdated] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const unblockNavigation = useNavigationConfirmation(dirty, props.translate, undefined, undefined);
    const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);

    useEffect(() => {
        props.setTitle(marketingList?.name || props.translate('MARKETING_LIST').toString());
    }, [marketingList]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (params.listId) {
            const listId = parseFloat(params.listId);
            if (listId !== marketingList?.id) {
                getMarketingList(listId);
            }
        }
    }, [params.listId]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (isCreateMode()) {
            setCreateModalVisible(true);
        } else if (isValueSet(marketingList?.id)){
            getMarketingList(marketingList!.id)
        }
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const getMarketingList = async (id: number) => {
        const list = await getMarketingListAPI(id)
        setMarketingList(list || undefined);
        getMarketingListCustomers(list);
        setDirty(false);
    }
    
    const getMarketingListCustomers = async (list = marketingList, pSize = pageSize, pNumber = pageNumber) => {
        setIsLoading(true);
        let params: any = {
            pageSize: pSize,
            pageNumber: pNumber,
            filter: list!.filter
        }
        if (isValueSet(list?.id)) {
            params = {
                ...params,
                listId: list!.id
            }
            setCustomers(await getMarketingListCustomersAPI(params) || []);
            setCustomersCount(await countMarketingListCustomers(params) || 0);
        } else {
            setCustomers(await getMarketingListCustomersPreview(params) || []);
            setCustomersCount(await countMarketingListCustomersPreview(params) || 0);
        }
        setIsOutdated(false);
        setIsLoading(false);
    }
    
    const onFilterChange = async (filter: Partial<MarketingListCustomersFilter>) => {
        const updatedList = {
            ...(marketingList || {}),
            filter: {
                ...(marketingList?.filter || {}),
                ...filter
            }
        };
        setMarketingList(updatedList);
        setDirty(true);
        if (filter.hasOwnProperty('customerAdvisors') || filter.hasOwnProperty('customerStatuses')) {
            getMarketingListCustomers(updatedList);
        } else {
            setIsOutdated(true);
        }
    }

    const updateCustomerFollowUp = async (customer: MarketingListCustomer, followUpDate: Date | null) => {
        const result = await putFollowUpDate(customer.personId, customer.applicationId, followUpDate);
        handleCommandResult(result, props, 'MARKETING_LISTS_VIEW.FOLLOW_UP_DATE_UPDATE_SUCCESS');
    }
    
    const updateCustomerStatus = async (customer: MarketingListCustomer, status: MarketingListCustomerStatus) => {
        if (isValueSet(marketingList?.id)) {
            const result = await postMarketingListCustomerStatus(marketingList!.id, customer.personId, status);
            handleCommandResult(result, props, 'MARKETING_LISTS_VIEW.CUSTOMER_STATUS_UPDATE_SUCCESS');
            if (result.success) {
                getMarketingListCustomers();
            }
        }
    }
    
    const saveCustomerComment = async (customer: MarketingListCustomer, comment: string) => {
        const result = await postComment(customer.personId, customer.applicationId, comment);
        handleCommandResult(result, props, 'SAVE_COMMENT_SUCCESS');
        if (result.success) {
            getMarketingListCustomers();
        }
    }
    
    const createMarketingList = async (list: MarketingList, copy?: boolean) => {
        const result = await createMarketingListAPI(list);
        handleCommandResult(result, props, `MARKETING_LISTS_VIEW.${copy ? 'COPY_MARKETING_LIST_SUCCESS' : 'CREATE_MARKETING_LIST_SUCCESS'}`);
        if (result.success) {
            navigate(`/marketing-list/${result.listId}`);
        }
    }
    
    const deleteMarketingList = async (id?: number) => {
        if (isValueSet(id)) {
            const result = await deleteMarketingListAPI(id);
            handleCommandResult(result, props, 'MARKETING_LISTS_VIEW.DELETE_MARKETING_LIST_SUCCESS');
            if (result.success) {
                navigate('/marketing-lists');
            }            
        }
    }
    
    const saveMarketingList = async () => {
        if (isCreateMode()) {
            createMarketingList({
                ...(marketingList || {}),
                id: undefined
            }, location.pathname.includes('copy'))
        } else if (marketingList) {
            putMarketingList(marketingList.id!, marketingList);
        }
        setDirty(false);
        unblockNavigation();
    }

    const isAdminOrCreator = () => {
        const isCreator = props.userData.user?.displayName === marketingList?.username;
        return isAdmin(props) || isCreator;
    }
    
    const isCreateMode = () => location.pathname.includes('copy') || location.pathname.includes('new');
    
    const onPageNumberChange = (pNumber: number) => {
        setPageNumber(pNumber);
        getMarketingListCustomers(marketingList, pageSize, pNumber);
    }
    
    const onPageSizeChange = (pSize: number) => {
        setPageSize(pSize);
        getMarketingListCustomers(marketingList, pSize, pageNumber);
    }
    
    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 pt-4 px-0 marketing-list-view">
                <div className="top-panel px-5">
                    {marketingList?.name &&
                        <>
                            <TextInputField
                                name="marketingListName"
                                className="marketing-list-name"
                                value={marketingList.name}
                                editMode={true}
                                onValueChanged={name => {setMarketingList({...marketingList, name}); setDirty(true);}}
                            />
                            {isValueSet(marketingList.id) && !isCreateMode() && 
                                <MarketingListMenu
                                    onCopyClick={() => navigate(`/marketing-list/${marketingList.id}/copy`)}
                                    onDeleteClick={() => deleteMarketingList(marketingList.id)}
                                />
                            }
                        </>
                    }
                </div>
                <div className="content-panel px-5">
                    <div className="customers-filter">
                        <CustomersFilterContainer
                            editMode={isAdminOrCreator()}
                            countryId={getCountryId(props)}
                            filter={marketingList?.filter}
                            onChange={onFilterChange}
                        />
                    </div>
                    <div className="customers-table">
                        <CustomersTable
                            customers={customers}
                            editable={!isCreateMode()}
                            isLoading={isLoading}
                            isOutdated={isOutdated}
                            count={customersCount}
                            pageSize={pageSize}
                            pageNumber={pageNumber}
                            onPageChange={onPageNumberChange}
                            onPageSizeChange={onPageSizeChange}
                            onRefreshDataClick={() => isOutdated ? onPageNumberChange(0) : getMarketingListCustomers()}
                            onCustomerFollowUpDateChange={updateCustomerFollowUp}
                            onCustomerStatusChange={updateCustomerStatus}
                            onCustomerCommentSave={saveCustomerComment}
                        />
                    </div>
                    <div className="marketing-list-actions">
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                if (isCreateMode()) {
                                    navigate(-1);
                                } else {
                                    navigate('/marketing-lists');
                                }
                                setDirty(false);
                            }}
                        ><Translate id="CANCEL"/></Button>
                        <Button 
                            disabled={!dirty && !isCreateMode()} 
                            onClick={saveMarketingList}
                        ><Translate id="SAVE"/></Button>
                    </div>
                </div>
                <CreateMarketingListModal
                    show={createModalVisible}
                    list={marketingList}
                    onSubmit={(list) => {
                        setCreateModalVisible(false);
                        setMarketingList(list);
                        getMarketingListCustomers(list);
                        setDirty(true);
                    }}
                    onCancel={() => {
                        setCreateModalVisible(false);
                        setDirty(false);
                        navigate(-1);
                    }}
                />
            </div>
        </main>
    );
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...SetTitleActionCreator,
    showToastMessage
}, dispatch);

export default connect<SettingsProps & UserProps, SetApplicationTitleActionProps & ShowToastMessageProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(MarketingListView));